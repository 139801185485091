import Navbar from '../../module/common/Navbar.vue'
import Tabbar from '../common/Tabbar.vue'
import Loading from '../../module/common/Loading.vue'
import Share from '../../module/common/Share.vue'
import '../../../assets/icon/font-icon.js'
import { Tag, Icon, Tabs, Tab, Image, Cell } from 'vant'
export default {
    components: {
        Navbar: Navbar,
        Tabbar: Tabbar,
        Loading: Loading,
        Share: Share,
        [Tag.name]: Tag,
        [Icon.name]: Icon,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Image.name]: Image,
        [Cell.name]: Cell
    },
    data () {
        return {
            loadingShow: false,
            user: {},
            wallet: {},
            orderList: [],
            refereeList: [],
            amount: 0,
            tabActive: 'order',
            orderCode: '2',
            settle: { prior: 0, wait: 0, complete: 0 },
            payOrderCode: '1'
        }
    },
    mounted () {
        document.title = '个人中心-工作'
        this.retrieveUser()
        this.$refs.share.mainDefault()
    },
    methods: {
        async retrieveWallet () {
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/wallet/retrieveWallet')
            if (res.status === '200') {
                this.wallet = res.data
            }
        },
        walletRecord () {
            this.$router.push({ path: '/mde/wallet/record/customer' })
        },
        walletExtract () {
            this.$router.push({ path: '/mde/wallet/extract/customer' })
        },
        async retrieveSeller () {
            var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
            var pd = { code: seller }
            const { data: res } = await this.$http.post(this.UMS_URL + '/sell/seller/retrieveSeller', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.seller = res.data
                this.retrieveWallet()
            }
        },
        async retrieveUser () {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { code: user }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/retrieveUser', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.user = res.data
                this.retrieveWallet()
            }
        },
        batchApply () {
            this.$router.push({ path: '/mde/overall/batch' })
        },
        recruitApply () {
            this.$router.push({ path: '/mde/recruit/order' })
        },
        informApply () {
            this.$router.push({ path: '/mde/inform/order' })
        },
        reseverApply () {
            this.$router.push({ path: '/mde/overall/resever' })
        },
        consultApply () {
            this.$router.push({ path: '/mde/overall/consult' })
        },
        signIn () {
            this.$dialog.alert({
                title: '提示信息',
                message: '签到成功'
            })
        },
        recmd () {
            this.$router.push({ path: '/mde/main/recmd' })
        }
    }
}
