<template>
  <div class="page">
    <Navbar title="个人中心" type="SMP" />
    <van-row class="top">
      <van-col span="6"><van-icon name="user-circle-o" size="50px" /></van-col>
      <van-col span="10">
        <van-row>
          <van-col span="24" class="name"
            >{{ user.name }} <van-icon name="edit"
          /></van-col>
        </van-row>
        <van-row>
          <van-col span="24" class="grade"
            ><van-tag :color="COLOR_M">普通会员</van-tag></van-col
          >
        </van-row>
      </van-col>
      <van-col span="8" class="task"
        ><van-button
          icon="plus"
          :color="COLOR_S1"
          size="mini"
          @click="signIn"
          plain
          >立即签到</van-button
        ></van-col
      >
    </van-row>
    <div class="wallet">
      <van-row>
        <van-col span="5" class="icon"
          ><van-icon name="balance-pay" size="40px"
        /></van-col>
        <van-col span="10" class="amount">￥{{ wallet.coinBalance }}元</van-col>
        <van-col span="9" class="more">
          <!-- <van-button
            plain
            :color="COLOR_S1"
            icon="balance-list-o"
            size="mini"
            @click="walletRecord"
            >明细</van-button
          ><van-button
            plain
            :color="COLOR_S2"
            icon="cash-back-record"
            size="mini"
            @click="walletExtract"
            >提现</van-button
          > -->
        </van-col>
      </van-row>
    </div>
    <div class="advice">
      <van-image
        class="img"
        :src="require('../../../assets/images/train/adv-01.jpg')"
      ></van-image>
    </div>
    <div class="navigate">
      <van-cell @click="batchApply()" is-link>
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-batch"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 批量申请</van-col>
          </van-row>
        </template></van-cell
      ><van-cell @click="recruitApply()" is-link>
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-recruit"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 岗位申请</van-col>
          </van-row>
        </template></van-cell
      ><van-cell @click="informApply()" is-link>
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-inform"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 公招申请</van-col>
          </van-row>
        </template></van-cell
      >
      <van-cell @click="reseverApply()" is-link>
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-resever"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 岗位预订</van-col>
          </van-row>
        </template></van-cell
      >
      <van-cell @click="consultApply()" is-link>
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-consult-1"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 服务咨询</van-col>
          </van-row>
        </template></van-cell
      >
      <!-- <van-cell @click="recmd()" is-link>
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-tuijian"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 我的推荐</van-col>
          </van-row>
        </template></van-cell
      > -->
    </div>
    <Loading :show="loadingShow" />
    <Tabbar active="own" />
    <Share ref="share" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
